.retry{
    color: blue !important;
    text-decoration: underline !important;
}
.customClass {
    padding-top: 0.75rem;
    padding-bottom: 0.5rem;
    font-size: 1.25rem;
    font-weight: 800;
    font-size: 24px;
    color: #12141d;
    font-family: "Inter", sans-serif;
}

@media screen and (min-width: 768px) {
    .customClass {
        padding-top: 0.75rem;
        font-size: 1.875rem;
    }
}

@media screen and (min-width: 1024px) {
    .customClass {
        padding-top: 0.75rem;
        padding-bottom: 0.5rem;
        font-size: 2.25rem;
    }
}
#textp{
    overflow-wrap:  break-word !important;
}
.pdfCont{
    height: 100vh;
}
#container{
    height: 100vh !important;
}
#textbox{
    height: 20vh;
    overflow: scroll;
}